import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Header from './common/Header'
import Footer from './common/Footer'
import Loading from './common/Loading'

const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const News = lazy(() => import('./pages/News'))
const Achievements = lazy(() => import('./pages/Achievements'))
const Gallery = lazy(() => import('./pages/Gallery'))
const Dohodnina = lazy(() => import('./pages/Dohodnina'))
const Admin = lazy(() => import('./admin/Admin'))

export default () => (
  <Router>
    <Header />
    <Suspense fallback={<Loading />}>
      <Route path="/" exact component={Home} />
      <Route path="/about" component={About} />
      <Route path="/dohodnina" component={Dohodnina} />
      <Route path="/achievements" component={Achievements} />

      <Route path="/news" exact component={News} />
      <Route path="/news/:newsId" exact component={News} />

      <Route path="/gallery" exact component={Gallery} />
      <Route path="/gallery/:galleryId" exact component={Gallery} />

      <Route path="/admin" component={Admin} />
    </Suspense>
    <Footer />
  </Router>
)
