import React from 'react'
import { Link } from 'react-router-dom'
import Picture from './Picture'

const links = [
  {
    title: 'Domov',
    to: '/',
  },
  {
    title: 'Novice',
    to: '/news',
  },
  {
    title: 'Uspehi',
    to: '/achievements',
  },
  {
    title: 'Galerija',
    to: '/gallery',
  },
  {
    title: 'Dohodnina',
    to: '/dohodnina',
  },
]

export default () => {
  return (
    <header>
      <nav className="navbar navbar-expand-sm justify-content-end navbar-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <Picture image="/images/logo-white" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="ml-auto collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
            <ul className="navbar-nav text-right">
              {links.map((link, index) => {
                return (
                  <li key={index} className="nav-item">
                    <Link className="nav-link" to={link.to}>
                      {link.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
