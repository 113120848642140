import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Picture from './Picture'

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer mt-auto">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <Link to={'/admin'}>
                  <Picture className="img" image="/images/logo-white" alt="Logo" />
                </Link>
                <p className="font-weight-light">
                  KD Godba Zgornje savinjske doline
                  <br />
                  Hribernikova 1, 3330 Mozirje
                </p>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <h5>PREDSEDNICA</h5>
                <p className="font-weight-light">Maja Sternad</p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <h5>KONTAKTNA OSEBA</h5>
                <p className="font-weight-light">Matej Kranjc</p>
                <p className="font-weight-light">
                  <i className="fas fa-envelope" /> <a href="mail:info@godba.org">info@godba.org</a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <i className="fas fa-phone" /> <a href="tel:041 374 098">041 374 098</a>
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <h5>DIRIGENT</h5>
                <p className="font-weight-light">Tomaž Podlesnik</p>
                <p className="font-weight-light">
                  <i className="fas fa-envelope" />{' '}
                  <a href="mail:podlesnik.tomaz@gmail.com">podlesnik.tomaz@gmail.com</a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <i className="fas fa-phone" /> <a href="tel:031743078">031 743 078</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mini text-center py-2">
          <div className="social-icons">
            <a
              href="https://www.facebook.com/Godba-na-pihala-Zgornje-Savinjske-doline-216343758379402/"
              target="_blanc"
            >
              <i className="fab fa-facebook-f" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://twitter.com/GodbaZgSavdolin" target="_blanc">
              <i className="fab fa-twitter" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.instagram.com/godbazgornjesavinjskedoline/" target="_blanc">
              <i className="fab fa-instagram" />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.youtube.com/playlist?list=PL6FFrRD5HvZY3cgGcgGrA4ePGyr_f_rV2" target="_blanc">
              <i className="fab fa-youtube" />
            </a>
          </div>
          <div className="copyright">
            &copy; Godba Zgornje Savinjske Doline {new Date().getFullYear()} | Vse pravice zadržane
          </div>
        </div>
      </footer>
    )
  }
}
