import React from 'react'

export default () => {
  return (
    <main id="loading">
      <div className="py-5 bg-light">
        <div className="container text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </main>
  )
}
