import React from 'react'

export default function Picture(props) {
  return (
    <picture className="d-block" style={props.style || {}}>
      <source className={props.className || ''} srcSet={`${props.image}.webp`} type="image/webp" alt={props.alt} />
      <source className={props.className || ''} srcSet={`${props.image}.png`} type="image/png" alt={props.alt} />
      <img className={props.className || ''} src={`${props.image}.png`} alt={props.alt} />
    </picture>
  )
}
